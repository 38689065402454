import {mobInitialState} from './initialStates';
import * as types from '../actions/types';

export const mobReducer = (state = mobInitialState , action) => {
    switch(action.type){
        case types.BUY_MOB :
            return ({
                ...state,
                mobile : state.mobile.map( brand => {
                    if(brand.brand === action.payload.brand){
                        return ({
                            ...brand,
                            mobs : brand.mobs.map( mob => {
                                if(mob.mobName === action.payload.mobName){
                                    return ({
                                        ...mob,
                                        num : +mob.num - action.payload.num
                                    });
                                } else {
                                    return mob;
                                }
                            })
                        });
                    } else {
                        return brand;
                    }
                } )
            });
        default : return state ;
    }
}