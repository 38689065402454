export const mobInitialState = {
    mobile : [
        {
            brand : "samsung",
            mobs : [
                {
                    mobName : "a71",
                    num : 25
                },
                {
                    mobName : "a50",
                    num : 42
                },
                {
                    mobName : "note20 lite",
                    num : 10
                },
                {
                    mobName : "s20 lite",
                    num : 50
                }
            ]
        },
        {
            brand : "apple",
            mobs : [
                {
                    mobName : "iphone 5s",
                    num : 12
                },
                {
                    mobName : "iphone 6s",
                    num : 4
                },
                {
                    mobName : "iphone 11 promax",
                    num : 26
                },
                {
                    mobName : "iphone 7",
                    num : 5
                }
            ]
        },
        {
            brand : "huawei",
            mobs : [
                {
                    mobName : "mate40 pro",
                    num : 18
                },
                {
                    mobName : "p30 pro",
                    num : 50
                },
                {
                    mobName : "p30 lite",
                    num : 70
                },
                {
                    mobName : "mate20 lite",
                    num : 8
                },
                {
                    mobName : "mate10 pro",
                    num : 12
                },
                {
                    mobName : "p40 pro",
                    num : 41
                }
            ]
        },
        {
            brand : "sony",
            mobs : [
                {
                    mobName : "xperia XZ",
                    num : 32
                },
                {
                    mobName : "xperia XZ2",
                    num : 14
                },
                {
                    mobName : "xperia one",
                    num : 18
                },
                {
                    mobName : "xperia z5",
                    num : 12
                }
            ]
        }
    ]
}
