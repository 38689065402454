import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import store from './redux/store';
import { Provider } from 'react-redux';
const Form = React.lazy(() => import('./components/Form'));


class App extends Component {
  render() {
    return (
      <Provider store={store}>
          <section id="main" className="d-flex flex-column w-100 text-dark position-relative" >
                <h2 id="heading" className="text-center m-0 border-bottom border-secondary" >Mobile Store</h2>
                <React.Suspense fallback={<div id="main-spinner" className="spinner-border text-dark position-absolute"></div>}>
                    <Form />
                </React.Suspense>
          </section>
      </Provider>
    );
  }
}

export default App;
